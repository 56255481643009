import { Injectable, TemplateRef } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  toasts: any[] = [];

  show(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    let toast = { textOrTpl, ...options }
    this.toasts.push(toast);
    return toast;
  }

  remove(toast: any) {
    this.toasts = this.toasts.filter(t => t != toast);
  }
  
}
