<div class="w-100">
    <ngb-toast *ngFor="let toast of toastService.toasts" [class]="toast.classname" [autohide]="true"
    [delay]="toast.delay || 5000" (click)="closeClick(toast)" >{{toast.body}}
    <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
        <i class="mr-3 bi bi-file-earmark-person"></i>
        <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
    </ng-template>

    <ng-template #text>
        <div class="d-flex align-items-center">
            <i class="mr-3 bi bi-check-circle-fill"></i>
            <div>{{ toast.textOrTpl }}</div>
        </div>
    </ng-template>
</ngb-toast>

</div>