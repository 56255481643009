import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { ToastService } from 'src/app/services/toast.service';
import validator from "validar-telefone";
import { cnpj } from 'cpf-cnpj-validator';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  stage = false;
  show = true;
  formulario: FormGroup;

  public error = {
    phone: {
      message: ''
    },
    email: {
      message: ''
    },
    investimento: {
      message: ''
    },
    cnpj: {
      message: ''
    }
  }

  constructor(private api: ApiService,
    private toastService: ToastService) {
    this.formulario = new FormGroup({
      nome: new FormControl(null, [Validators.required]),
      email: new FormControl(null, [Validators.required, Validators.email]),
      telefone: new FormControl(null, [Validators.required]),
      cnpj: new FormControl(null, [Validators.required]),
      investimento: new FormControl(null, [Validators.required]),
      comentario: new FormControl(null, [Validators.required])
    });
  }
  ngOnInit(): void {
  }

  phoneNumberIsReal(data: string) {
    if (data.length >= 10) {
      if (!validator(data)) {
        this.error.phone.message = 'O telefone digitado não é válido.'
        this.formulario.controls.telefone.setErrors({ 'invalid': true })
        this.formulario.controls.telefone.markAsTouched()
      } else {
        this.error.phone.message = ''
      }
    } else {
      this.error.phone.message = 'O campo telefone é obrigatório.'
    }
  }

  validacaoEmail(field: string) {
    if (field) {
      let usuario = field.substring(0, field.indexOf("@"));
      let dominio = field.substring(field.indexOf("@") + 1, field.length);
      if ((usuario.length >= 1) &&
        (dominio.length >= 3) &&
        (usuario.search("@") == -1) &&
        (dominio.search("@") == -1) &&
        (usuario.search(" ") == -1) &&
        (dominio.search(" ") == -1) &&
        (dominio.search(".") != -1) &&
        (dominio.indexOf(".") >= 1) &&
        (dominio.lastIndexOf(".") < dominio.length - 1)) {
        this.error.email.message = ''
        this.formulario.controls.email.setErrors(null)
      } else {
        this.error.email.message = 'O e-mail digitado não é válido.'
        this.formulario.controls.email.setErrors({ 'invalid': true })
      }
    } else {
      this.error.email.message = 'O campo e-mail é obrigatório.'
    }
  }

  testCnpj() {
    const cnpjIsValid = cnpj.isValid(this.formulario.value.cnpj);
    if (!cnpjIsValid) {
      this.toastService.show('O CNPJ digitado é inválido', {
        classname: 'centered-blue'
      })
      return false;
    }
    return true;
  }

  submit() {
    let body = this.formulario.value;
    if (this.formulario.invalid) {
      this.toastService.show('Preencha todos os campos antes de enviar sua mensagem', {
        classname: 'centered-blue'
      })
      return
    } else {
      const cnpjIsValid = this.testCnpj();
      if(!cnpjIsValid) return;
      body.comentario = `${body.comentario} \n
      Investimento: ${body.investimento} \n
      CNPJ: ${body.cnpj}
      `
      delete body.cnpj;
      delete body.investimento;
    }
    this.stage = true;
    setTimeout(() => {
      this.stage = false;
      this.toastService.show('Mensagem enviada com sucesso!', {
        classname: 'centered-blue'
      })
      this.formulario.reset();
    }, 1000);
    this.api.post(body).subscribe(res => { })
  }

}
