<div class="site clr outer-wrap" style="  overflow-x: hidden !important;
">
  <a class="skip-link screen-reader-text" href="#main">Skip to content</a>
  <div class="clr wrap">
    <a id="scroll-top" class="scroll-top-right" href="#" style="display: block;">
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="9pt" height="30pt"
        viewBox="0 0 11 40" version="1.1">
        <g id="surface1">
          <path style=" stroke:none;fill-rule:nonzero;fill:rgb(252, 252, 252);fill-opacity:1;"
            d="M 6.085938 12.476562 L 10.757812 23.101562 C 11.082031 23.835938 11.082031 25.023438 10.757812 25.75 L 9.984375 27.515625 C 9.660156 28.25 9.136719 28.25 8.816406 27.515625 L 5.5 19.992188 L 2.1875 27.523438 C 1.863281 28.257812 1.339844 28.257812 1.019531 27.523438 L 0.242188 25.757812 C -0.0820312 25.023438 -0.0820312 23.835938 0.242188 23.109375 L 4.914062 12.484375 C 5.238281 11.742188 5.761719 11.742188 6.085938 12.476562 Z M 6.085938 12.476562 " />
        </g>
      </svg>
    </a>
    <header class="minimal-header effect-four clr site-header" role="banner">
      <div class="clr container site-header-inner d-flex">
        <div class="clr site-logo">
          <div class="clr site-logo-inner">
            <a href="assets/AGA-CODE-2-1.png" class="custom-logo-link" rel="home" aria-current="page">
              <img width="145" height="110" src="assets/AGA-CODE-2-1.png" class="custom-logo" alt="Agacode"></a>
          </div>
        </div>
        <div class="site-navigation-wrap">
          <nav class="navbar navbar-expand-lg navbar-light" role="navigation">
            <div class="collapse navbar-collapse">
              <!-- <div class="sidr-inner">
                  <a class="navbar-brand" aria-label="Close mobile Menu">
                    <i class="sidr-class-icon icon-close" aria-hidden="true"></i><span class="sidr-class-close-text">Fechar
                      Menu</span>
                  </a>
                </div> -->
              <div class="sidr-inner menu-text-pointer">
                <a class="navbar-brand" aria-label="Close mobile Menu" href="#sobre-nos">
                  <span class="font-menu">Agacode</span>
                </a>
              </div>
              <div class="sidr-inner menu-text-pointer">
                <a class="navbar-brand" aria-label="Close mobile Menu" href="#como-trabalhamos">
                  <span class="font-menu">Como
                    trabalhamos</span>
                </a>
              </div>
              <div class="sidr-inner menu-text-pointer">
                <a class="navbar-brand" aria-label="Close mobile Menu" href="#o-que-fazemos">
                  <span class="font-menu">O que
                    fazemos</span>
                </a>
              </div>
              <div class="sidr-inner menu-text-pointer">
                <a class="navbar-brand" aria-label="Close mobile Menu" href="#tecnologia">
                  <span class="font-menu">Tecnologia</span>
                </a>
              </div>
              <div class="sidr-inner menu-text-pointer">
                <a class="navbar-brand" aria-label="Close mobile Menu" href="#contato">
                  <span class="font-menu">Contato</span>
                </a>
              </div>
              <div class="sidr-inner menu-text-pointer">
                <a class="navbar-brand"
                  href="https://api.whatsapp.com/send?phone=5511973159766&amp;text=Olá,%20tudo%20bem?%20Preciso%20de%20um%20orçamento."
                  aria-label="Close mobile Menu">
                  <span class=" btn sidr-class-close-text">11
                    97315-9766</span>
                </a>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </header>

    <main class="site-main clr" role="main">
      <div class=" clr">
        <div class="content-area clr">
          <div class="site-content clr">
            <article class="single-page-article clr">
              <div class="entry clr">
                <div class="elementor elementor-16">
                  <div class="elementor-inner">
                    <div class="elementor-section-wrap">
                      <section
                        class="elementor-section elementor-top-section elementor-element elementor-element-2879617 elementor-section-height-min-height elementor-section-boxed elementor-section-height-default elementor-section-items-middle">
                        <div class="elementor-background-overlay"></div>
                        <div class="elementor-container elementor-column-gap-default">
                          <div class="elementor-row">
                            <div
                              class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-5cfeeed">
                              <div class="elementor-column-wrap elementor-element-populated">
                                <div class="elementor-widget-wrap">
                                  <div
                                    class="elementor-element elementor-element-685dc49e elementor-widget elementor-widget-heading">
                                    <div class="elementor-widget-wrap">
                                      <h3 class="elementor-heading-title elementor-size-default">Software sob medida
                                        para a <b class="b-ajuste">transformação digital da sua empresa!</b></h3>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-e1cbcb2">
                              <div class="elementor-column-wrap elementor-element-populated">
                                <div class="elementor-widget-wrap">
                                  <div
                                    class="elementor-element elementor-element-87fdd64 elementor-button-align-stretch elementor-widget elementor-widget-form">
                                    <div class="elementor-widget-container">
                                      <form [formGroup]="formulario" class="elementor-form">
                                        <div class="elementor-form-fields-wrapper elementor-labels-">
                                          <div
                                            class="elementor-field-type-text elementor-field-group elementor-column elementor-field-group-name elementor-col-100 elementor-field-required form-group">
                                            <label for="nome"
                                              class="elementor-field-label elementor-screen-only">Nome</label><input
                                              type="text"
                                              class="elementor-field elementor-size-md  elementor-field-textual form-control"
                                              placeholder="Nome" name="nome" id="nome" formControlName="nome" required>
                                          </div>
                                          <div
                                            class="elementor-field-type-email elementor-field-group elementor-column elementor-field-group-3kxrmft elementor-col-50 elementor-field-required form-group">
                                            <label for="email"
                                              class="elementor-field-label elementor-screen-only">Email</label><input
                                              type="email"
                                              class="elementor-field elementor-size-md  elementor-field-textual form-control"
                                              placeholder="Email" name="email" id="email" formControlName="email"
                                              required [ngClass]="{
                                                'invalid-form-field':
                                                  formulario.controls.email.invalid &&
                                                  formulario.controls.email.touched
                                              }" on-focusout="validacaoEmail(formulario.controls.email.value)">
                                            <small style=" color: red">
                                              {{ error.email.message }}
                                            </small>
                                          </div>
                                          <div
                                            class="elementor-field-type-text elementor-field-group elementor-column elementor-field-group-cpt3f1i elementor-col-50 elementor-field-required form-group">
                                            <label for="telefone"
                                              class="elementor-field-label elementor-screen-only">Telefone</label>
                                            <input mask="(00) 00000-0000 || (00) 0000-0000" name="telefone"
                                              id="telefone" formControlName="telefone" type="text"
                                              class="elementor-field elementor-size-md  elementor-field-textual form-control"
                                              placeholder="Telefone" required [ngClass]="{
                                                'invalid-form-field':
                                                 this.formulario.controls.telefone.invalid &&
                                                 this.formulario.controls.telefone.touched
                                              }" on-focusout="phoneNumberIsReal(formulario.controls.telefone.value)">
                                            <small style="left: 5px; top: -15px; color: red"> {{ error.phone.message }}
                                            </small>
                                          </div>

                                          <div
                                            class="elementor-field-type-email elementor-field-group elementor-column elementor-field-group-3kxrmft elementor-col-50 elementor-field-required form-group">
                                            <label for="cnpj"
                                              class="elementor-field-label elementor-screen-only">CNPJ</label>
                                            <input (focusout)="testCnpj()" type="cnpj" mask="00.000.000/0000-00"
                                              class="elementor-field elementor-size-md  elementor-field-textual form-control"
                                              placeholder="CNPJ" name="cnpj" id="cnpj" formControlName="cnpj" required
                                              [ngClass]="{
                                              'invalid-form-field':
                                                formulario.controls.cnpj.invalid &&
                                                formulario.controls.cnpj.touched
                                            }">
                                            <small style=" color: red">
                                              {{ error.cnpj.message }}
                                            </small>
                                          </div>
                                          <div
                                            class="elementor-field-type-text elementor-field-group elementor-column elementor-field-group-cpt3f1i elementor-col-50 elementor-field-required form-group">
                                            <label for="investimento"
                                              class="elementor-field-label elementor-screen-only">Investimento
                                              (R$)</label>

                                            <select
                                              class="elementor-field elementor-size-md  elementor-field-textual form-control"
                                              name="investimento" required id="investimento"
                                              formControlName="investimento" [ngClass]="{
                                                'invalid-form-field':
                                                 this.formulario.controls.investimento.invalid &&
                                                 this.formulario.controls.investimento.touched
                                              }">
                                              <option [value]="null" hidden>Valor do investimento</option>
                                              <option value="0_15000">até R$15.000</option>
                                              <option value="15000_30000">R$15.000 a R$30.000</option>
                                              <option value="30000_50000">R$30.000 a R$50.000</option>
                                              <option value="50000+">Acima de R$50.000</option>
                                            </select>
                                            <small style="left: 5px; top: -15px; color: red"> {{
                                              error.investimento.message }}
                                            </small>
                                          </div>


                                          <div
                                            class="elementor-field-type-textarea elementor-field-group elementor-column elementor-field-group-field_27c0279 elementor-col-100 elementor-field-required form-group">
                                            <label for="comentario"
                                              class="elementor-field-label elementor-screen-only">Comente
                                              aqui o que
                                              precisa</label><textarea
                                              class="elementor-field-textual elementor-field  elementor-size-md form-control"
                                              name="comentario" id="comentario" formControlName="comentario"
                                              placeholder="Comente aqui o que precisa..." required></textarea>
                                          </div>
                                          <div
                                            class="elementor-field-group elementor-column elementor-field-type-submit elementor-col-100 e-form__buttons">
                                            <button (click)="submit()" type="submit"
                                              class="elementor-button elementor-size-md">
                                              <span>
                                                <div [hidden]="!stage" class="spinner-border spinner-border-sm mr-2"
                                                  role="status">
                                                  <span class="sr-only">Loading...</span>
                                                </div>
                                                <span class=" elementor-button-icon">
                                                </span>
                                                <span class="elementor-button-text">
                                                  enviar
                                                </span>
                                              </span>
                                            </button>
                                          </div>
                                        </div>
                                      </form>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                      <section id="sobre-nos"
                        class="elementor-section elementor-top-section elementor-element elementor-element-31a9785 elementor-section-boxed elementor-section-height-default elementor-section-height-default">
                        <div class="elementor-container elementor-column-gap-default">
                          <div class="elementor-row">
                            <div
                              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ff503b0">
                              <div class="elementor-column-wrap elementor-element-populated">
                                <div class="elementor-widget-wrap">
                                  <div
                                    class="elementor-element elementor-element-3a4c803 elementor-widget elementor-widget-menu-anchor">
                                    <div class="elementor-widget-container">
                                      <div class="elementor-menu-anchor"></div>
                                    </div>
                                  </div>
                                  <div
                                    class="elementor-element elementor-element-f7ecffe elementor-widget elementor-widget-heading">
                                    <div class="elementor-widget-container">
                                      <h3 class="elementor-heading-title elementor-size-default">Sobre nós</h3>
                                    </div>
                                  </div>
                                  <div
                                    class="elementor-element elementor-element-15f635e elementor-widget elementor-widget-text-editor">
                                    <div class="elementor-widget-container">
                                      <div class="elementor-text-editor elementor-clearfix">
                                        <p>A Agacode IT Solutions tem como objetivo a prestação de serviços em TI
                                          através de integração de sistemas, desenvolvimento de componentes, sistemas
                                          e sustentação.<br>Com grande vivência em tecnologia, a Agacode IT Solutions
                                          tem competência para colaborar com os desafios tecnológicos oriundos da
                                          área. Em um momento onde mudanças acontecem de forma rápida, a Agacode IT
                                          Solutions possui experiência em prover soluções ágeis que suportem essas
                                          mudanças.</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                      <section
                        class="elementor-section elementor-top-section elementor-element elementor-element-4126f1ee elementor-section-boxed elementor-section-height-default elementor-section-height-default">
                        <div class="elementor-container elementor-column-gap-wider">
                          <div class="elementor-row">
                            <div
                              class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-74e314d7">
                              <div class="elementor-column-wrap elementor-element-populated">
                                <div class="elementor-widget-wrap">
                                  <div
                                    class="elementor-element elementor-element-55e78287 elementor-view-framed elementor-position-left elementor-vertical-align-middle elementor-shape-circle elementor-widget elementor-widget-icon-box">
                                    <div class="elementor-widget-container">
                                      <div class="elementor-icon-box-wrapper">
                                        <div class="elementor-icon-box-icon">
                                          <span class="elementor-icon elementor-animation-">
                                            <svg aria-hidden="true" focusable="false" data-prefix="fas"
                                              data-icon="briefcase" class="svg-inline--fa fa-briefcase fa-w-16 "
                                              role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                              <path fill="currentColor"
                                                d="M320 336c0 8.84-7.16 16-16 16h-96c-8.84 0-16-7.16-16-16v-48H0v144c0 25.6 22.4 48 48 48h416c25.6 0 48-22.4 48-48V288H320v48zm144-208h-80V80c0-25.6-22.4-48-48-48H176c-25.6 0-48 22.4-48 48v48H48c-25.6 0-48 22.4-48 48v80h512v-80c0-25.6-22.4-48-48-48zm-144 0H192V96h128v32z">
                                              </path>
                                            </svg> </span>
                                        </div>
                                        <div class="elementor-icon-box-content">
                                          <h3 class="h3-ajuste-title">
                                            <span> Experiência</span>
                                          </h3>
                                          <p class="elementor-icon-box-description">Desenvolvemos projetos para os
                                            mais variados segmentos. Trabalhamos com uma abordagem de pesquisa para
                                            entender a fundo as necessidades e complexidades de cada negócio</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="elementor-element elementor-element-1446d811 elementor-widget elementor-widget-spacer">
                                    <div class="elementor-widget-container">
                                      <div class="elementor-spacer">
                                        <div class="elementor-spacer-inner"></div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="elementor-element elementor-element-433145d6 elementor-view-framed elementor-position-left elementor-vertical-align-middle elementor-shape-circle elementor-widget elementor-widget-icon-box">
                                    <div class="elementor-widget-container">
                                      <div class="elementor-icon-box-wrapper">
                                        <div class="elementor-icon-box-icon">
                                          <span class="elementor-icon elementor-animation-">
                                            <svg aria-hidden="true" focusable="false" data-prefix="fas"
                                              data-icon="thumbs-up" class="svg-inline--fa fa-thumbs-up fa-w-16 fa-2x"
                                              role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                              <path fill="currentColor"
                                                d="M104 224H24c-13.255 0-24 10.745-24 24v240c0 13.255 10.745 24 24 24h80c13.255 0 24-10.745 24-24V248c0-13.255-10.745-24-24-24zM64 472c-13.255 0-24-10.745-24-24s10.745-24 24-24 24 10.745 24 24-10.745 24-24 24zM384 81.452c0 42.416-25.97 66.208-33.277 94.548h101.723c33.397 0 59.397 27.746 59.553 58.098.084 17.938-7.546 37.249-19.439 49.197l-.11.11c9.836 23.337 8.237 56.037-9.308 79.469 8.681 25.895-.069 57.704-16.382 74.757 4.298 17.598 2.244 32.575-6.148 44.632C440.202 511.587 389.616 512 346.839 512l-2.845-.001c-48.287-.017-87.806-17.598-119.56-31.725-15.957-7.099-36.821-15.887-52.651-16.178-6.54-.12-11.783-5.457-11.783-11.998v-213.77c0-3.2 1.282-6.271 3.558-8.521 39.614-39.144 56.648-80.587 89.117-113.111 14.804-14.832 20.188-37.236 25.393-58.902C282.515 39.293 291.817 0 312 0c24 0 72 8 72 81.452z">
                                              </path>
                                            </svg> </span>
                                        </div>
                                        <div class="elementor-icon-box-content">
                                          <h3 class="h3-ajuste-title">
                                            <span>Simplicidade para o usuário</span>
                                          </h3>
                                          <p class="elementor-icon-box-description">Valorizamos uma excelente
                                            experiência de uso. Para nós as soluções precisam ser funcionais e
                                            intuitivas.</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-64af78a6">
                              <div class="elementor-column-wrap elementor-element-populated">
                                <div class="elementor-widget-wrap">
                                  <div
                                    class="elementor-element elementor-element-5205c96e elementor-view-framed elementor-position-left elementor-vertical-align-middle elementor-shape-circle elementor-widget elementor-widget-icon-box">
                                    <div class="elementor-widget-container">
                                      <div class="elementor-icon-box-wrapper">
                                        <div class="elementor-icon-box-icon">
                                          <span class="elementor-icon elementor-animation-">
                                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="tv"
                                              class="svg-inline--fa fa-tv fa-w-20 fa-2x " role="img"
                                              xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                                              <path fill="currentColor"
                                                d="M592 0H48A48 48 0 0 0 0 48v320a48 48 0 0 0 48 48h240v32H112a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16H352v-32h240a48 48 0 0 0 48-48V48a48 48 0 0 0-48-48zm-16 352H64V64h512z">
                                              </path>
                                            </svg> </span>
                                        </div>
                                        <div class="elementor-icon-box-content">
                                          <h3 class="h3-ajuste-title">
                                            <span>Cultura tecnológica</span>
                                          </h3>
                                          <p class="elementor-icon-box-description">Nos mantemos sempre atualizados
                                            com o que há de mais novo em metodologias e tecnologias de desenvolvimento
                                            de software, para aplicar a melhor abordagem em cada projeto</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="elementor-element elementor-element-087612d elementor-widget elementor-widget-spacer">
                                    <div class="elementor-widget-container">
                                      <div class="elementor-spacer">
                                        <div class="elementor-spacer-inner"></div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="elementor-element elementor-element-5f3f3310 elementor-view-framed elementor-position-left elementor-vertical-align-middle elementor-shape-circle elementor-widget elementor-widget-icon-box">
                                    <div class="elementor-widget-container">
                                      <div class="elementor-icon-box-wrapper">
                                        <div class="elementor-icon-box-icon">
                                          <span class="elementor-icon elementor-animation-">
                                            <svg aria-hidden="true" focusable="false" data-prefix="fas"
                                              data-icon="calendar-check"
                                              class="svg-inline--fa fa-calendar-check fa-w-14 fa-2x" role="img"
                                              xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                              <path fill="currentColor"
                                                d="M436 160H12c-6.627 0-12-5.373-12-12v-36c0-26.51 21.49-48 48-48h48V12c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v52h128V12c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v52h48c26.51 0 48 21.49 48 48v36c0 6.627-5.373 12-12 12zM12 192h424c6.627 0 12 5.373 12 12v260c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V204c0-6.627 5.373-12 12-12zm333.296 95.947l-28.169-28.398c-4.667-4.705-12.265-4.736-16.97-.068L194.12 364.665l-45.98-46.352c-4.667-4.705-12.266-4.736-16.971-.068l-28.397 28.17c-4.705 4.667-4.736 12.265-.068 16.97l82.601 83.269c4.667 4.705 12.265 4.736 16.97.068l142.953-141.805c4.705-4.667 4.736-12.265.068-16.97z">
                                              </path>
                                            </svg> </span>
                                        </div>
                                        <div class="elementor-icon-box-content">
                                          <h3 class="h3-ajuste-title">
                                            <span>Entrega no prazo</span>
                                          </h3>
                                          <p class="elementor-icon-box-description">Para nós o prazo de entrega
                                            acordado é um compromisso assumido. Sabemos que o atraso de um projeto
                                            impacta diretamente o resultado dos nossos clientes</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                      <section id="como-trabalhamos"
                        class="elementor-section elementor-top-section elementor-element elementor-element-463b8e6 elementor-section-boxed elementor-section-height-default elementor-section-height-default">
                        <div class="elementor-container elementor-column-gap-default">
                          <div class="elementor-row">
                            <div
                              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-e95c9c6">
                              <div class="elementor-column-wrap elementor-element-populated">
                                <div class="elementor-widget-wrap">
                                  <div
                                    class="elementor-element elementor-element-45448ff elementor-widget elementor-widget-menu-anchor">
                                    <div class="elementor-widget-container">
                                      <div id="comotrabalhamos" class="elementor-menu-anchor"></div>
                                    </div>
                                  </div>
                                  <div
                                    class="elementor-element elementor-element-ea97e28 elementor-widget elementor-widget-heading">
                                    <div class="elementor-widget-container">
                                      <h3 class="elementor-heading-title elementor-size-default">Como trabalhamos</h3>
                                    </div>
                                  </div>
                                  <div
                                    class="elementor-element elementor-element-90a19d8 elementor-widget elementor-widget-text-editor">
                                    <div class="elementor-widget-container">
                                      <div class="elementor-text-editor elementor-clearfix">
                                        <p>Veja como iremos conduzir o seu projeto</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                      <section
                        class="elementor-section elementor-top-section elementor-element elementor-element-3ce2d8b elementor-section-boxed elementor-section-height-default elementor-section-height-default">
                        <div class="elementor-container elementor-column-gap-default">
                          <div class="elementor-row">
                            <div
                              class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-1741902">
                              <div class="elementor-column-wrap elementor-element-populated">
                                <div class="elementor-widget-wrap">
                                  <div
                                    class="elementor-element elementor-element-4243a730 elementor-widget__width-auto elementor-widget elementor-widget-heading">
                                    <div class="elementor-widget-container">
                                      <h2 class="elementor-heading-title elementor-size-default">01</h2>
                                    </div>
                                  </div>
                                  <div
                                    class="elementor-element elementor-element-4e1f1e6 elementor-widget elementor-widget-text-editor">
                                    <div class="container-ajustable-padding-1">
                                      <div class="elementor-text-editor elementor-clearfix">
                                        <h2 class="ajuste-h2"><strong>Entendimento</strong></h2>
                                        <p>Através de pesquisas e reuniões de trabalho, nós entendemos o problema que
                                          precisamos resolver, apresentamos uma solução e refinamos a 4 mãos.</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-1741902">
                              <div class="elementor-column-wrap elementor-element-populated">
                                <div class="elementor-widget-wrap">
                                  <div
                                    class="elementor-element elementor-element-4243a730 elementor-widget__width-auto elementor-widget elementor-widget-heading">
                                    <div class="elementor-widget-container">
                                      <h2 class="elementor-heading-title elementor-size-default">02</h2>
                                    </div>
                                  </div>
                                  <div
                                    class="elementor-element elementor-element-4e1f1e6 elementor-widget elementor-widget-text-editor">
                                    <div class=" container-ajustable-padding-2">
                                      <div class="elementor-text-editor elementor-clearfix">
                                        <h2 class="ajuste-h2"><strong>Prototipação</strong></h2>
                                        <p>Iremos desenvolver os protótipos da solução contando com o apoio de um
                                          especialista em UX. Após discussões, refinamentos e aprovação do cliente,
                                          utilizaremos os documentos e protótipos gerados nesta fase também no
                                          desenvolvimento.</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-1741902">
                              <div class="elementor-column-wrap elementor-element-populated">
                                <div class="elementor-widget-wrap">
                                  <div
                                    class="elementor-element elementor-element-4243a730 elementor-widget__width-auto elementor-widget elementor-widget-heading">
                                    <div class="elementor-widget-container">
                                      <h2 class="elementor-heading-title elementor-size-default">03</h2>
                                    </div>
                                  </div>
                                  <div
                                    class="elementor-element elementor-element-4e1f1e6 elementor-widget elementor-widget-text-editor">
                                    <div class="container-ajustable-padding-3">
                                      <div class="elementor-text-editor elementor-clearfix">
                                        <h2 class="ajuste-h2"><strong>Planejamento/Desenvolvimento</strong></h2>
                                        <p>Nesta fase conseguiremos montar todo o cronograma do projeto. Ao iniciar o
                                          desenvolvimento, nós combinaremos as reuniões semanais para apresentarmos as
                                          evoluções do projeto.</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                      <section
                        class="elementor-section elementor-top-section elementor-element elementor-element-d21074e elementor-section-boxed elementor-section-height-default elementor-section-height-default">
                        <div class="elementor-container elementor-column-gap-default">
                          <div class="elementor-row">
                            <div
                              class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-07b7cda">
                              <div class="elementor-column-wrap elementor-element-populated">
                                <div class="elementor-widget-wrap">
                                  <div
                                    class="elementor-element elementor-element-245e35e elementor-widget__width-auto elementor-widget elementor-widget-heading">
                                    <div class="elementor-widget-container">
                                      <h2 class="elementor-heading-title elementor-size-default">04</h2>
                                    </div>
                                  </div>
                                  <div
                                    class="elementor-element elementor-element-d14e6fe elementor-widget elementor-widget-text-editor">
                                    <div class="elementor-widget-container">
                                      <div class="elementor-text-editor elementor-clearfix">
                                        <h2 class="ajuste-h2"><strong>Homologação</strong></h2>
                                        <p>Iremos liberar o sistema desenvolvido para que o cliente possa iniciar a
                                          homologação e este será o momento que trataremos possíveis falhas de
                                          desenvolvimento.</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-1f9323d">
                              <div class="elementor-column-wrap elementor-element-populated">
                                <div class="elementor-widget-wrap">
                                  <div
                                    class="elementor-element elementor-element-e0641c5 elementor-widget__width-auto elementor-widget elementor-widget-heading">
                                    <div class="elementor-widget-container">
                                      <h2 class="elementor-heading-title elementor-size-default">05</h2>
                                    </div>
                                  </div>
                                  <div
                                    class="elementor-element elementor-element-53b6255 elementor-widget elementor-widget-text-editor">
                                    <div class="container-ajustable-padding-5">
                                      <div class="elementor-text-editor elementor-clearfix">
                                        <h2 class="ajuste-h2"><strong>Implantação em produção</strong></h2>
                                        Disponibilizaremos a solução em ambiente real e apoiaremos tecnicamente
                                        durante todo o período de garantia definido em contrato.
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-9464084">
                              <div class="elementor-column-wrap elementor-element-populated">
                                <div class="elementor-widget-wrap">
                                  <div
                                    class="elementor-element elementor-element-86c5348 elementor-widget__width-auto elementor-widget elementor-widget-heading">
                                    <div class="elementor-widget-container">
                                      <h2 class="elementor-heading-title elementor-size-default">06</h2>
                                    </div>
                                  </div>
                                  <div
                                    class="elementor-element elementor-element-7ede6ed elementor-widget elementor-widget-text-editor">
                                    <div class="elementor-widget-container">
                                      <div class="elementor-text-editor elementor-clearfix">
                                        <h2 class="ajuste-h2"><strong>Garantia e suporte</strong></h2>
                                        <p>Realizamos a correção de qualquer defeito encontrado durante o prazo de
                                          garantia. Caso seja desejo do cliente, ele pode contratar o serviço de
                                          suporte</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                      <section id="o-que-fazemos"
                        class="elementor-section elementor-top-section elementor-element elementor-element-5d794743 elementor-section-boxed elementor-section-height-default elementor-section-height-default">
                        <div class="elementor-background-overlay"></div>
                        <div class="elementor-container elementor-column-gap-default">
                          <div class="elementor-row">
                            <div
                              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-25cc2288">
                              <div class="elementor-column-wrap elementor-element-populated">
                                <div class="elementor-widget-wrap">
                                  <section
                                    class="elementor-section elementor-inner-section elementor-element elementor-element-700209fd elementor-section-boxed elementor-section-height-default elementor-section-height-default">
                                    <div class="elementor-container elementor-column-gap-default">
                                      <div class="elementor-row">
                                        <div
                                          class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-61a8b513">
                                          <div class="elementor-column-wrap elementor-element-populated">
                                            <div class="elementor-widget-wrap">
                                              <div
                                                class="elementor-element elementor-element-b88b8a7 elementor-widget elementor-widget-menu-anchor">
                                                <div class="elementor-widget-container">
                                                  <div id="oquefazemos" class="elementor-menu-anchor"></div>
                                                </div>
                                              </div>
                                              <div
                                                class="elementor-element elementor-element-0f1daf4 elementor-widget elementor-widget-heading">
                                                <div class="elementor-widget-container">
                                                  <h3 class="elementor-heading-title elementor-size-default">Nossos
                                                    serviços</h3>
                                                </div>
                                              </div>
                                              <div
                                                class="elementor-element elementor-element-386e0c6 elementor-widget elementor-widget-text-editor">
                                                <div class="elementor-widget-container">
                                                  <div class="elementor-text-editor elementor-clearfix">
                                                    <p>Aplicamos as metodologias e tecnologias mais atuais para
                                                      descomplicar a criação do seu sistema WEB ou aplicativo móvel.
                                                      Dos projetos mais simples aos mais complexos, estaremos com
                                                      vocês em cada etapa dessa jornada</p>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </section>
                                  <section
                                    class="elementor-section elementor-inner-section elementor-element elementor-element-742abf8f elementor-section-boxed elementor-section-height-default elementor-section-height-default">
                                    <div class="elementor-container elementor-column-gap-default">
                                      <div class="elementor-row">
                                        <div
                                          class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-1170e53e">
                                          <div class="elementor-column-wrap elementor-element-populated">
                                            <div class="elementor-widget-wrap">
                                              <div
                                                class="elementor-element elementor-element-10c9f16 elementor-view-default elementor-widget elementor-widget-icon">
                                                <div class="elementor-widget-container">
                                                  <div class="elementor-icon-wrapper">
                                                    <div class="elementor-icon">
                                                      <svg aria-hidden="true" focusable="false" data-prefix="fas"
                                                        data-icon="laptop"
                                                        class="svg-inline--fa fa-laptop fa-w-20 fa-7x" role="img"
                                                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                                                        <path fill="currentColor"
                                                          d="M624 416H381.54c-.74 19.81-14.71 32-32.74 32H288c-18.69 0-33.02-17.47-32.77-32H16c-8.8 0-16 7.2-16 16v16c0 35.2 28.8 64 64 64h512c35.2 0 64-28.8 64-64v-16c0-8.8-7.2-16-16-16zM576 48c0-26.4-21.6-48-48-48H112C85.6 0 64 21.6 64 48v336h512V48zm-64 272H128V64h384v256z">
                                                        </path>
                                                      </svg>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                class="elementor-element elementor-element-7f5ccb1e elementor-position-top elementor-vertical-align-top elementor-widget elementor-widget-image-box">
                                                <div class="elementor-widget-container">
                                                  <div class="elementor-image-box-wrapper">
                                                    <div class="elementor-image-box-content">
                                                      <h3 class="elementor-image-box-title">Desenvolvimento de
                                                        sistemas WEB</h3>
                                                      <p class="elementor-image-box-description">Sistemas WEB para
                                                        automatização de processos, portais complexos e migração de
                                                        sistemas para a nuvem</p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                class="elementor-element elementor-element-5a96cf2b elementor-align-left elementor-mobile-align-center elementor-widget elementor-widget-button">
                                                <div class="elementor-widget-container">
                                                  <div class="elementor-button-wrapper">
                                                    <a href="#contato"
                                                      class="elementor-button-link elementor-button elementor-size-sm elementor-animation-grow"
                                                      role="button">
                                                      <span class="elementor-button-content-wrapper">
                                                        <span class="elementor-button-icon elementor-align-icon-right">
                                                          <svg aria-hidden="true" focusable="false" data-prefix="fas"
                                                            data-icon="arrow-right"
                                                            class="svg-inline--fa fa-arrow-right fa-w-14" role="img"
                                                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                                            <path fill="currentColor"
                                                              d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z">
                                                            </path>
                                                          </svg>
                                                        </span>
                                                        <span class="elementor-button-text">Iniciar projeto</span>
                                                      </span>
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-a1343df">
                                          <div class="elementor-column-wrap elementor-element-populated">
                                            <div class="elementor-widget-wrap">
                                              <div
                                                class="elementor-element elementor-element-d27e0ea elementor-view-default elementor-widget elementor-widget-icon">
                                                <div class="elementor-widget-container">
                                                  <div class="elementor-icon-wrapper">
                                                    <div class="elementor-icon">
                                                      <svg aria-hidden="true" focusable="false" data-prefix="fas"
                                                        data-icon="mobile-alt"
                                                        class="svg-inline--fa fa-mobile-alt fa-w-10" role="img"
                                                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                                        <path fill="currentColor"
                                                          d="M272 0H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h224c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48zM160 480c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm112-108c0 6.6-5.4 12-12 12H60c-6.6 0-12-5.4-12-12V60c0-6.6 5.4-12 12-12h200c6.6 0 12 5.4 12 12v312z">
                                                        </path>
                                                      </svg>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                class="elementor-element elementor-element-baa2b19 elementor-position-top elementor-vertical-align-top elementor-widget elementor-widget-image-box">
                                                <div class="elementor-widget-container">
                                                  <div class="elementor-image-box-wrapper">
                                                    <div class="elementor-image-box-content">
                                                      <h3 class="elementor-image-box-title">Desenvolvimento de apps
                                                      </h3>
                                                      <p class="elementor-image-box-description">Aplicativos sob
                                                        medida nas plataformas android e iOS para que você esteja
                                                        sempre perto do seu cliente</p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                class="elementor-element elementor-element-35d206b elementor-align-left elementor-mobile-align-center elementor-widget elementor-widget-button">
                                                <div class="elementor-widget-container">
                                                  <div class="elementor-button-wrapper">
                                                    <a href="#contato"
                                                      class="elementor-button-link elementor-button elementor-size-sm elementor-animation-grow"
                                                      role="button">
                                                      <span class="elementor-button-content-wrapper">
                                                        <span class="elementor-button-icon elementor-align-icon-right">
                                                          <svg aria-hidden="true" focusable="false" data-prefix="fas"
                                                            data-icon="arrow-right"
                                                            class="svg-inline--fa fa-arrow-right fa-w-14" role="img"
                                                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                                            <path fill="currentColor"
                                                              d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z">
                                                            </path>
                                                          </svg>
                                                        </span>
                                                        <span class="elementor-button-text">Iniciar projeto</span>
                                                      </span>
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-c316af6">
                                          <div class="elementor-column-wrap elementor-element-populated">
                                            <div class="elementor-widget-wrap">
                                              <div
                                                class="elementor-element elementor-element-98b8b09 elementor-view-default elementor-widget elementor-widget-icon">
                                                <div class="elementor-widget-container">
                                                  <div class="elementor-icon-wrapper">
                                                    <div class="elementor-icon">
                                                      <svg aria-hidden="true" focusable="false" data-prefix="fas"
                                                        data-icon="object-ungroup"
                                                        class="svg-inline--fa fa-object-ungroup fa-w-18" role="img"
                                                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                                        <path fill="currentColor"
                                                          d="M64 320v26a6 6 0 0 1-6 6H6a6 6 0 0 1-6-6v-52a6 6 0 0 1 6-6h26V96H6a6 6 0 0 1-6-6V38a6 6 0 0 1 6-6h52a6 6 0 0 1 6 6v26h288V38a6 6 0 0 1 6-6h52a6 6 0 0 1 6 6v52a6 6 0 0 1-6 6h-26v192h26a6 6 0 0 1 6 6v52a6 6 0 0 1-6 6h-52a6 6 0 0 1-6-6v-26H64zm480-64v-32h26a6 6 0 0 0 6-6v-52a6 6 0 0 0-6-6h-52a6 6 0 0 0-6 6v26H408v72h8c13.255 0 24 10.745 24 24v64c0 13.255-10.745 24-24 24h-64c-13.255 0-24-10.745-24-24v-8H192v72h-26a6 6 0 0 0-6 6v52a6 6 0 0 0 6 6h52a6 6 0 0 0 6-6v-26h288v26a6 6 0 0 0 6 6h52a6 6 0 0 0 6-6v-52a6 6 0 0 0-6-6h-26V256z">
                                                        </path>
                                                      </svg>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                class="elementor-element elementor-element-cccbe32 elementor-position-top elementor-vertical-align-top elementor-widget elementor-widget-image-box">
                                                <div class="elementor-widget-container">
                                                  <div class="elementor-image-box-wrapper">
                                                    <div class="elementor-image-box-content">
                                                      <h3 class="elementor-image-box-title">Sustentação e melhorias
                                                      </h3>
                                                      <p class="elementor-image-box-description">Sustentação, suporte
                                                        e melhorias para sistemas já existentes em sua empresa, ainda
                                                        que não desenvolvidos por nós</p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                class="elementor-element elementor-element-d030e50 elementor-align-left elementor-mobile-align-center elementor-widget elementor-widget-button">
                                                <div class="elementor-widget-container">
                                                  <div class="elementor-button-wrapper">
                                                    <a href="#contato"
                                                      class="elementor-button-link elementor-button elementor-size-sm elementor-animation-grow"
                                                      role="button">
                                                      <span class="elementor-button-content-wrapper">
                                                        <span class="elementor-button-icon elementor-align-icon-right">
                                                          <svg aria-hidden="true" focusable="false" data-prefix="fas"
                                                            data-icon="arrow-right"
                                                            class="svg-inline--fa fa-arrow-right fa-w-14" role="img"
                                                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                                            <path fill="currentColor"
                                                              d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z">
                                                            </path>
                                                          </svg>
                                                        </span>
                                                        <span class="elementor-button-text">Iniciar projeto</span>
                                                      </span>
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </section>
                                  <section
                                    class="elementor-section elementor-inner-section elementor-element elementor-element-291333c elementor-section-boxed elementor-section-height-default elementor-section-height-default">
                                    <div class="elementor-container elementor-column-gap-default">
                                      <div class="elementor-row">
                                        <div
                                          class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-f3e404e">
                                          <div class="elementor-column-wrap">
                                            <div class="elementor-widget-wrap">
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-94a23db">
                                          <div class="elementor-column-wrap elementor-element-populated">
                                            <div class="elementor-widget-wrap">
                                              <div
                                                class="elementor-element elementor-element-83a2e3f elementor-view-default elementor-widget elementor-widget-icon">
                                                <div class="elementor-widget-container">
                                                  <div class="elementor-icon-wrapper">
                                                    <div class="elementor-icon">
                                                      <svg aria-hidden="true" focusable="false" data-prefix="fas"
                                                        data-icon="compress-arrows-alt"
                                                        class="svg-inline--fa fa-compress-arrows-alt fa-w-16" role="img"
                                                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                        <path fill="currentColor"
                                                          d="M200 288H88c-21.4 0-32.1 25.8-17 41l32.9 31-99.2 99.3c-6.2 6.2-6.2 16.4 0 22.6l25.4 25.4c6.2 6.2 16.4 6.2 22.6 0L152 408l31.1 33c15.1 15.1 40.9 4.4 40.9-17V312c0-13.3-10.7-24-24-24zm112-64h112c21.4 0 32.1-25.9 17-41l-33-31 99.3-99.3c6.2-6.2 6.2-16.4 0-22.6L481.9 4.7c-6.2-6.2-16.4-6.2-22.6 0L360 104l-31.1-33C313.8 55.9 288 66.6 288 88v112c0 13.3 10.7 24 24 24zm96 136l33-31.1c15.1-15.1 4.4-40.9-17-40.9H312c-13.3 0-24 10.7-24 24v112c0 21.4 25.9 32.1 41 17l31-32.9 99.3 99.3c6.2 6.2 16.4 6.2 22.6 0l25.4-25.4c6.2-6.2 6.2-16.4 0-22.6L408 360zM183 71.1L152 104 52.7 4.7c-6.2-6.2-16.4-6.2-22.6 0L4.7 30.1c-6.2 6.2-6.2 16.4 0 22.6L104 152l-33 31.1C55.9 198.2 66.6 224 88 224h112c13.3 0 24-10.7 24-24V88c0-21.3-25.9-32-41-16.9z">
                                                        </path>
                                                      </svg>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                class="elementor-element elementor-element-40f4dca elementor-position-top elementor-vertical-align-top elementor-widget elementor-widget-image-box">
                                                <div class="elementor-widget-container">
                                                  <div class="elementor-image-box-wrapper">
                                                    <div class="elementor-image-box-content">
                                                      <h3 class="elementor-image-box-title">Integração entre sistemas
                                                      </h3>
                                                      <p class="elementor-image-box-description">Desenvolvimento de
                                                        APIs e integração entre sistemas para otimização dos processos
                                                        da sua empresa</p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                class="elementor-element elementor-element-543f632 elementor-align-left elementor-mobile-align-center elementor-widget elementor-widget-button">
                                                <div class="elementor-widget-container">
                                                  <div class="elementor-button-wrapper">
                                                    <a href="#contato"
                                                      class="elementor-button-link elementor-button elementor-size-sm elementor-animation-grow"
                                                      role="button">
                                                      <span class="elementor-button-content-wrapper">
                                                        <span class="elementor-button-icon elementor-align-icon-right">
                                                          <svg aria-hidden="true" focusable="false" data-prefix="fas"
                                                            data-icon="arrow-right"
                                                            class="svg-inline--fa fa-arrow-right fa-w-14" role="img"
                                                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                                            <path fill="currentColor"
                                                              d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z">
                                                            </path>
                                                          </svg>
                                                        </span>
                                                        <span class="elementor-button-text">Iniciar projeto</span>
                                                      </span>
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-7660353">
                                          <div class="elementor-column-wrap elementor-element-populated">
                                            <div class="elementor-widget-wrap">
                                              <div
                                                class="elementor-element elementor-element-93daacc elementor-view-default elementor-widget elementor-widget-icon">
                                                <div class="elementor-widget-container">
                                                  <div class="elementor-icon-wrapper">
                                                    <div class="elementor-icon">
                                                      <svg aria-hidden="true" focusable="false" data-prefix="fas"
                                                        data-icon="thumbs-up"
                                                        class="svg-inline--fa fa-thumbs-up fa-w-16" role="img"
                                                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                        <path fill="currentColor"
                                                          d="M104 224H24c-13.255 0-24 10.745-24 24v240c0 13.255 10.745 24 24 24h80c13.255 0 24-10.745 24-24V248c0-13.255-10.745-24-24-24zM64 472c-13.255 0-24-10.745-24-24s10.745-24 24-24 24 10.745 24 24-10.745 24-24 24zM384 81.452c0 42.416-25.97 66.208-33.277 94.548h101.723c33.397 0 59.397 27.746 59.553 58.098.084 17.938-7.546 37.249-19.439 49.197l-.11.11c9.836 23.337 8.237 56.037-9.308 79.469 8.681 25.895-.069 57.704-16.382 74.757 4.298 17.598 2.244 32.575-6.148 44.632C440.202 511.587 389.616 512 346.839 512l-2.845-.001c-48.287-.017-87.806-17.598-119.56-31.725-15.957-7.099-36.821-15.887-52.651-16.178-6.54-.12-11.783-5.457-11.783-11.998v-213.77c0-3.2 1.282-6.271 3.558-8.521 39.614-39.144 56.648-80.587 89.117-113.111 14.804-14.832 20.188-37.236 25.393-58.902C282.515 39.293 291.817 0 312 0c24 0 72 8 72 81.452z">
                                                        </path>
                                                      </svg>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                class="elementor-element elementor-element-6f3f851 elementor-position-top elementor-vertical-align-top elementor-widget elementor-widget-image-box">
                                                <div class="elementor-widget-container">
                                                  <div class="elementor-image-box-wrapper">
                                                    <div class="elementor-image-box-content">
                                                      <h3 class="elementor-image-box-title">Consultoria em
                                                        transformação digital</h3>
                                                      <p class="elementor-image-box-description">Diagnóstico da sua
                                                        empresa para entender como a tecnologia pode aumentar a
                                                        eficiência dos processos</p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                class="elementor-element elementor-element-109393d elementor-align-left elementor-mobile-align-center elementor-widget elementor-widget-button">
                                                <div class="elementor-widget-container">
                                                  <div class="elementor-button-wrapper">
                                                    <a href="#contato"
                                                      class="elementor-button-link elementor-button elementor-size-sm elementor-animation-grow"
                                                      role="button">
                                                      <span class="elementor-button-content-wrapper">
                                                        <span class="elementor-button-icon elementor-align-icon-right">
                                                          <svg aria-hidden="true" focusable="false" data-prefix="fas"
                                                            data-icon="arrow-right"
                                                            class="svg-inline--fa fa-arrow-right fa-w-14" role="img"
                                                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                                            <path fill="currentColor"
                                                              d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z">
                                                            </path>
                                                          </svg>
                                                        </span>
                                                        <span class="elementor-button-text">Iniciar projeto</span>
                                                      </span>
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-81ce5cb">
                                          <div class="elementor-column-wrap">
                                            <div class="elementor-widget-wrap">
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </section>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                      <section id="tecnologia"
                        class="elementor-section elementor-top-section elementor-element elementor-element-926d80d elementor-section-boxed elementor-section-height-default elementor-section-height-default">
                        <div class="elementor-container elementor-column-gap-default">
                          <div class="elementor-row">
                            <div
                              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-c29da8f">
                              <div class="elementor-column-wrap elementor-element-populated">
                                <div class="elementor-widget-wrap">
                                  <div
                                    class="elementor-element elementor-element-94c962b elementor-widget elementor-widget-menu-anchor">
                                    <div class="elementor-widget-container">
                                      <div class="elementor-menu-anchor"></div>
                                    </div>
                                  </div>
                                  <div
                                    class="elementor-element elementor-element-812549a elementor-widget elementor-widget-heading">
                                    <div class="elementor-widget-container">
                                      <h3 class="elementor-heading-title elementor-size-default">Tecnologias</h3>
                                    </div>
                                  </div>
                                  <div
                                    class="elementor-element elementor-element-14f27a2 elementor-widget elementor-widget-text-editor">
                                    <div class="elementor-widget-container">
                                      <div class="elementor-text-editor elementor-clearfix">
                                        <p>Algumas das principais tecnologia que trabalhamos</p>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="elementor-element elementor-element-559aeec gallery-spacing-custom elementor-widget elementor-widget-image-gallery">
                                    <div class="elementor-widget-container">
                                      <div class="elementor-image-gallery">
                                        <div class="gallery galleryid-16 gallery-columns-6 gallery-size-medium">
                                          <figure class="gallery-item">
                                            <div class="gallery-icon landscape">
                                              <a class="gallery-lightbox"><img width="90" height="90"
                                                  src="assets/html5.png" class="attachment-medium size-medium"
                                                  alt=""></a>
                                            </div>
                                          </figure>
                                          <figure class="gallery-item">
                                            <div class="gallery-icon landscape">
                                              <a class="gallery-lightbox"><img width="148" height="54"
                                                  src="assets/ios.png" class="attachment-medium size-medium" alt=""></a>
                                            </div>
                                          </figure>
                                          <figure class="gallery-item">
                                            <div class="gallery-icon landscape">
                                              <a class="gallery-lightbox"><img width="148" height="74"
                                                  src="assets/javascript.png" class="attachment-medium size-medium"
                                                  alt=""></a>
                                            </div>
                                          </figure>
                                          <figure class="gallery-item">
                                            <div class="gallery-icon landscape">
                                              <a class="gallery-lightbox"><img width="148" height="74"
                                                  src="assets/nodejs.png" class="attachment-medium size-medium"
                                                  alt=""></a>
                                            </div>
                                          </figure>
                                          <figure class="gallery-item">
                                            <div class="gallery-icon landscape">
                                              <a class="gallery-lightbox"><img width="148" height="109"
                                                  src="assets/react.png" class="attachment-medium size-medium"
                                                  alt=""></a>
                                            </div>
                                          </figure>
                                          <figure class="gallery-item">
                                            <div class="gallery-icon landscape">
                                              <a class="gallery-lightbox"><img width="148" height="83"
                                                  src="assets/android.png" class="attachment-medium size-medium"
                                                  alt=""></a>
                                            </div>
                                          </figure>
                                          <figure class="gallery-item">
                                            <div class="gallery-icon landscape">
                                              <a class="gallery-lightbox"><img width="148" height="111"
                                                  src="assets/angular.png" class="attachment-medium size-medium"
                                                  alt=""></a>
                                            </div>
                                          </figure>
                                          <figure class="gallery-item">
                                            <div class="gallery-icon landscape">
                                              <a class="gallery-lightbox"><img width="90" height="90"
                                                  src="assets/css3.png" class="attachment-medium size-medium"
                                                  alt=""></a>
                                            </div>
                                          </figure>
                                          <figure class="gallery-item">
                                            <div class="gallery-icon landscape">
                                              <a class="gallery-lightbox"><img width="148" height="105"
                                                  src="assets/java-1.png" class="attachment-medium size-medium" alt=""
                                                  loading="lazy"></a>
                                            </div>
                                          </figure>
                                          <figure class="gallery-item">
                                            <div class="gallery-icon landscape">
                                              <a class="gallery-lightbox"><img width="183" height="100"
                                                  src="assets/googlecloud.png" class="attachment-medium size-medium"
                                                  alt=""></a>
                                            </div>
                                          </figure>
                                          <figure class="gallery-item">
                                            <div class="gallery-icon landscape">
                                              <a class="gallery-lightbox"><img width="250" height="100"
                                                  src="assets/AWS.png" class="attachment-medium size-medium" alt=""></a>
                                            </div>
                                          </figure>
                                          <figure class="gallery-item">
                                            <div class="gallery-icon landscape">
                                              <a class="gallery-lightbox"><img width="300" height="74"
                                                  src="assets/jqueryui-300x74.png" class="attachment-medium size-medium"
                                                  alt="" loading="lazy" sizes="(max-width: 300px) 100vw, 300px"></a>
                                            </div>
                                          </figure>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                      <section
                        class="elementor-section elementor-top-section elementor-element elementor-element-5eff480 elementor-section-boxed elementor-section-height-default elementor-section-height-default">
                        <div class="elementor-container elementor-column-gap-default">
                          <div class="elementor-row">
                            <div
                              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-cb0f659">
                              <div class="elementor-column-wrap elementor-element-populated">
                                <div class="elementor-widget-wrap">
                                  <div
                                    class="elementor-element elementor-element-643cebe elementor-widget elementor-widget-menu-anchor">
                                    <div class="elementor-widget-container">
                                      <div id="contato" class="elementor-menu-anchor"></div>
                                    </div>
                                  </div>
                                  <div
                                    class="elementor-element elementor-element-dcfa28b elementor-widget elementor-widget-heading">
                                    <div class="elementor-widget-container">
                                      <h3 class="elementor-heading-title elementor-size-default">Vamos agendar um bate
                                        papo para discutirmos sobre o seu projeto?</h3>
                                    </div>
                                  </div>
                                  <section
                                    class="elementor-section elementor-inner-section elementor-element elementor-element-260c9b6 elementor-section-boxed elementor-section-height-default elementor-section-height-default">
                                    <div class="elementor-container elementor-column-gap-default">
                                      <div class="elementor-row">
                                        <div
                                          class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-5b3fa04">
                                          <div class="elementor-column-wrap">
                                            <div class="elementor-widget-wrap">
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-45d5edb">
                                          <div class="elementor-column-wrap elementor-element-populated">
                                            <div class="elementor-widget-wrap">
                                              <div
                                                class="elementor-element elementor-element-764ab87 elementor-position-left elementor-view-default elementor-vertical-align-top elementor-widget elementor-widget-icon-box">
                                                <div class="elementor-widget-container">
                                                  <div class="elementor-icon-box-wrapper">
                                                    <div class="elementor-icon-box-icon">
                                                      <span class="elementor-icon elementor-animation-">
                                                        <svg aria-hidden="true" focusable="false" data-prefix="fas"
                                                          data-icon="mobile-alt"
                                                          class="svg-inline--fa fa-mobile-alt fa-w-10" role="img"
                                                          xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                                          <path fill="currentColor"
                                                            d="M272 0H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h224c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48zM160 480c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm112-108c0 6.6-5.4 12-12 12H60c-6.6 0-12-5.4-12-12V60c0-6.6 5.4-12 12-12h200c6.6 0 12 5.4 12 12v312z">
                                                          </path>
                                                        </svg> </span>
                                                    </div>
                                                    <div class="elementor-icon-box-content">
                                                      <h3 class="elementor-icon-box-title">
                                                        <span>11 3230-8812</span>
                                                      </h3>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-fbe78ac">
                                          <div class="elementor-column-wrap elementor-element-populated">
                                            <div class="elementor-widget-wrap">
                                              <div
                                                class="elementor-element elementor-element-918c009 elementor-position-left elementor-view-default elementor-vertical-align-top elementor-widget elementor-widget-icon-box">
                                                <div class="elementor-widget-container">
                                                  <div class="elementor-icon-box-wrapper">
                                                    <div class="elementor-icon-box-icon">
                                                      <span class="elementor-icon elementor-animation-">
                                                        <svg aria-hidden="true" focusable="false" data-prefix="fas"
                                                          data-icon="envelope"
                                                          class="svg-inline--fa fa-envelope fa-w-16" role="img"
                                                          xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                          <path fill="currentColor"
                                                            d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z">
                                                          </path>
                                                        </svg> </span>
                                                    </div>
                                                    <div class="elementor-icon-box-content">
                                                      <h3 class="elementor-icon-box-title">
                                                        <span>contato@agacode.com.br</span>
                                                      </h3>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-68028a2">
                                          <div class="elementor-column-wrap">
                                            <div class="elementor-widget-wrap">
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </section>
                                  <div
                                    class="elementor-element elementor-element-37b8d0ca elementor-button-align-stretch elementor-widget elementor-widget-form">
                                    <div class="elementor-widget-container">
                                      <form [formGroup]="formulario" class="elementor-form">

                                        <div class="elementor-form-fields-wrapper elementor-labels-">
                                          <div
                                            class="elementor-field-type-text elementor-field-group elementor-column elementor-field-group-name elementor-col-100 elementor-field-required form-group">
                                            <label for="nome"
                                              class="elementor-field-label elementor-screen-only">Nome</label><input
                                              type="text"
                                              class="elementor-field elementor-size-md  elementor-field-textual form-control"
                                              placeholder="Nome" name="nome" id="nome" formControlName="nome" required>
                                          </div>
                                          <div
                                            class="elementor-field-type-email elementor-field-group elementor-column elementor-field-group-3kxrmft elementor-col-50 elementor-field-required form-group">
                                            <label for="email"
                                              class="elementor-field-label elementor-screen-only">Email</label><input
                                              type="email"
                                              class="elementor-field elementor-size-md  elementor-field-textual form-control"
                                              placeholder="Email" name="email" id="email" formControlName="email"
                                              required>
                                          </div>
                                          <div
                                            class="elementor-field-type-text elementor-field-group elementor-column elementor-field-group-cpt3f1i elementor-col-50 elementor-field-required form-group">
                                            <label for="telefone"
                                              class="elementor-field-label elementor-screen-only">Telefone</label><input
                                              mask="(00) 00000-0000 || (00) 0000-0000" name="telefone" id="telefone"
                                              formControlName="telefone" type="text"
                                              class="elementor-field elementor-size-md  elementor-field-textual form-control"
                                              placeholder="Telefone" required>
                                          </div>
                                          <div
                                            class="elementor-field-type-email elementor-field-group elementor-column elementor-field-group-3kxrmft elementor-col-50 elementor-field-required form-group">
                                            <label for="cnpj"
                                              class="elementor-field-label elementor-screen-only">CNPJ</label>
                                            <input (focusout)="testCnpj()" type="text" mask="00.000.000/0000-00"
                                              class="elementor-field elementor-size-md  elementor-field-textual form-control"
                                              placeholder="CNPJ" name="cnpj" id="cnpj" formControlName="cnpj" required>
                                          </div>



                                          <div
                                            class="elementor-field-type-text elementor-field-group elementor-column elementor-field-group-cpt3f1i elementor-col-50 elementor-field-required form-group">
                                            <label for="investimento"
                                              class="elementor-field-label elementor-screen-only">Investimento
                                              (R$)</label>

                                            <select
                                              class="elementor-field elementor-size-md  elementor-field-textual form-control"
                                              name="investimento" required id="investimento"
                                              formControlName="investimento" [ngClass]="{
                                            'invalid-form-field':
                                             this.formulario.controls.investimento.invalid &&
                                             this.formulario.controls.investimento.touched
                                          }">
                                              <option [value]="null" hidden>Valor do investimento</option>
                                              <option value="0_15000">até R$15.000</option>
                                              <option value="15000_30000">R$15.000 a R$30.000</option>
                                              <option value="30000_50000">R$30.000 a R$50.000</option>
                                              <option value="50000+">Acima de R$50.000</option>
                                            </select>
                                          </div>
                                          <div
                                            class="elementor-field-type-textarea elementor-field-group elementor-column elementor-field-group-field_27c0279 elementor-col-100 elementor-field-required form-group">
                                            <label for="comentario"
                                              class="elementor-field-label elementor-screen-only">Comente
                                              aqui o que
                                              precisa</label><textarea
                                              class="elementor-field-textual elementor-field  elementor-size-md form-control"
                                              placeholder="Comente aqui o que precisa..." name="comentario"
                                              id="comentario" formControlName="comentario" required></textarea>
                                          </div>
                                          <div
                                            class="elementor-field-group elementor-column elementor-field-type-submit elementor-col-100 e-form__buttons">
                                            <button type="submit" class="elementor-button elementor-size-md">
                                              <span>
                                                <div [hidden]="!stage" class="spinner-border spinner-border-sm mr-2"
                                                  role="status">
                                                  <span class="sr-only">Loading...</span>
                                                </div>
                                                <span class=" elementor-button-icon">
                                                </span>
                                                <span class="elementor-button-text" (click)="submit()">
                                                  enviar
                                                </span>
                                              </span>
                                            </button>
                                          </div>
                                        </div>
                                      </form>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </main>
    <!--  <footer class="site-footer" role="contentinfo">
      <div class="clr">
        <div class="clr no-footer-nav">
          <div class="container clr">
            <div class="clr" role="contentinfo">
              <div class="wp-block-image">
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer> -->
  </div>
</div>

<div class="joinchat joinchat--right joinchat--show">
  <div class="joinchat__button">
    <a
      href="https://api.whatsapp.com/send?phone=5511973159766&amp;text=Olá,%20tudo%20bem?%20Preciso%20de%20um%20orçamento.">
      <div class="joinchat__button__open">

      </div>
      <div class="joinchat__button__sendtext">Abrir chat</div>
      <div class="joinchat__tooltip">
        <div>Olá, como podemos te ajudar?</div>
      </div>
    </a>

  </div>
</div>